import React, { Component } from "react";
import BoardTiles from "../components/BoardTiles";
import OldBoardPositions from "../components/OldBoardPositions"
import Helmet from "../components/Helmet"

export class Board extends Component {
  render() {
    return (
      <React.Fragment>
      <Helmet title="Board" desc="Isaac Kim is active in three executive boards as of Q1 2020. He has experience in botoh nonprofit and organizational boards." />
      <div className="board">
        <div className="hero is-primary" style={{ backgroundColor: "#05549b" }}>
          <div className="hero-body" style={{ paddingLeft: "0px" }}>
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <h1 className="title is-2">Current Board Memberships</h1>
                <h2 className="subtitle is-4">Q1 and Q2 2020</h2>
              </div>
            </div>
          </div>
        </div>
        <BoardTiles />
        <div className="hero is-primary" style={{ backgroundColor: "#05549b" }}>
          <div className="hero-body" style={{ paddingLeft: "0px" }}>
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <h1 className="title is-2">Previous Board Memberships</h1>
                <h2 className="subtitle is-4">
                  Non-current terms served on a board before Q1 2020
                </h2>
              </div>
            </div>
          </div>
        </div>
        <OldBoardPositions />
        <br />
      </div>
      </React.Fragment>
    );
  }
}

export default Board;
