import React, { Component } from "react";
import Ytembed from "./Ytembed"

export class FilmTilesTop extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="section">
          <div className="container">
            <div className="has-text-centered">
              <p className="title">Projects</p>
              <p className="subtitle">More projects avaiable by request.</p>
              <br />
            </div>
            <div className="columns">
              <div className="column is-4 is-offset-2">
                <Ytembed id="" title="Poached!" subtitle="Brown Motion Pictures" subsubtitle="AC + Lighting. 2019."/>
              </div>
              <div className="column is-4">
                <Ytembed id="yeErC1w_B2g" title="The Bakersfield Youth Symphony Orchestra @ Japan" subtitle="Solo Project. 2019." />
              </div>
            </div>
            <div className="columns">
              <div className="column is-4 is-offset-2">
                <Ytembed id="UX_rbNPAic8" title="Stockdale HOSA" subtitle="Solo Project. 2019."/>
              </div>
              <div className="column is-4">
                <Ytembed id="owjNx7N9VrY" title="California Boys State Whig Video" subtitle="Head Editor. 2018." />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FilmTilesTop;
