import React, { Component } from "react";
import PageHero from "../components/PageHero.js"
import Helmet from "../components/Helmet"

export class Music extends Component {
  render() {
    return (
      <React.Fragment>
      <Helmet
          title="Violinist"
          desc="Isaac is an experienced violinist, performing in orchestras, chamber groups, and as a soloist."
        />
        <PageHero bgurl="./img/isaac-concert.jpg" subtitle="Violinist" arrowhref="#" />
        <div className="container">
          <p className="subtitle is-3">Coming very soon!</p>
          <p>
            In the meantime, visit my <a href="https://musicofbakersfield.org/isaac-k">Music of Bakersfield profile </a> for more information, and for a complete resume, send me an email.
          </p>
        </div>

        <br />
      </React.Fragment>
    );
  }
}

export default Music;
