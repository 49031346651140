/* eslint-disable no-undef */
$(document).ready(function() {
  $(".navbar-burger").click(function() {
      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      $(".navbar-burger").toggleClass("is-active");
      $(".navbar-menu").toggleClass("is-active");
  });
  $(".navbar-item").click(function() {
    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    $(".navbar-burger").removeClass("is-active");
    $(".navbar-menu").removeClass("is-active");
});
});