/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";

export class Ytembed extends Component {

  render() {
  let yt
  let subtitle
  let subsubtitle

    if (this.props.id !== "" && this.props.id !== undefined) {
      yt = <div className="ytcontainer" style={{margin: "0 auto", display: "block"}}>
      <iframe
        className="ytvideo"
        src={`https://www.youtube-nocookie.com/embed/${this.props.id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
    } else {
      yt = <React.Fragment>
        <br/>
        <p className="subtitle has-text-centered">Video coming soon.</p>
      </React.Fragment>
    }

    if (this.props.subsubtitle !== undefined) {
      subtitle = <p className="title is-6" style={{marginBottom:"6px"}}>{this.props.subsubtitle}</p>
      subsubtitle = <p className="title is-6">{this.props.subtitle}</p>
    } else if (this.props.subtitle !== undefined) {
      subtitle = <p className="title is-6">{this.props.subtitle}</p>
    }
    return (
      <React.Fragment>
        <p className="title is-4" style={{marginBottom:"6px"}}>{this.props.title}</p>
        { subtitle }
        { subsubtitle }
        { yt }
      </React.Fragment>
    );
  }
}

export default Ytembed;
