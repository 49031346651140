import React, { Component } from "react";

export class BoardTiles extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="columns" style={{ marginTop: "12px", marginRight: "0px" }}>
          <div className="column is-2" style={{paddingLeft: "24px"}}>
            <img src="/img/bmp-logo.jpg" alt="Brown Motion Pictures logo" style={{maxHeight: "100px", float: "right"}}></img>
          </div>
          <div className="column is-8">
              <p className="title">Brown University Motion Pictures</p>
              <p className="subtitle" style={{ marginBottom: "6px" }}>
                Technology Director, Executive Board Member
              </p>
              <p className="subtitle is-6">Q1 2020 - Present</p>
              <p>University Student Organization</p>
            </div>
        </div>
        <div className="columns" style={{ marginTop: "12px", marginRight: "0px" }}>
          <div className="column is-2" style={{paddingLeft: "24px"}}>
            <img src="/img/buo-wide.jpg" alt="Brown University Orchestra logo" style={{maxHeight: "100px", float: "right"}}></img>
          </div>
          <div className="column is-8">
              <p className="title">Brown University Orchestra</p>
              <p className="subtitle" style={{ marginBottom: "6px" }}>
                Librarian, Community Manager, Executive Board Member
              </p>
              <p className="subtitle is-6">Q3 2019 - Present</p>
              <p>University Departamental Group</p>
            </div>
        </div>
        <div className="columns" style={{ marginTop: "12px", marginRight: "0px" }}>
          <div className="column is-2" style={{paddingLeft: "24px"}}>
            <img src="/img/brownesports.jpg" alt="Brown University Orchestra logo" style={{maxHeight: "100px", float: "right"}}></img>
          </div>
          <div className="column is-8">
              <p className="title">Brown Esports</p>
              <p className="subtitle" style={{ marginBottom: "6px" }}>
                Brown University Minecraft Replica Project Lead, Executive Board Member
              </p>
              <p className="subtitle is-6">Q3 2019 - Present</p>
              <p>University Student Organization</p>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BoardTiles;
