import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
//import ReactGA from "react-ga";
//import history from "./utils/history";
import Homepage from "./pages/Homepage";
import Music from "./pages/Music";
import Dev from "./pages/Dev";
import Board from "./pages/Board";
import Film from "./pages/Film";
import Chatbot from "./pages/Chatbot";
import Hours from "./pages/Hours";
import FourOhFour from "./pages/FourOhFour";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { ScrollToTop } from "./components/ScrollToTop";
import "./sass/styles.scss";
import "@mdi/font/css/materialdesignicons.css";
import "@mdi/font/fonts/materialdesignicons-webfont.ttf";

import WithTracker from "./WithTracker";

/*ReactGA.initialize("UA-156420319-1", { standardImplementation: true });

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});*/

function App() {
  return (
    <Router onUpdate={() => window.scrollTo(0, 0)}>
      <Navbar />
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          component={WithTracker(Homepage)}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/music`}
          component={WithTracker(Music)}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dev`}
          component={WithTracker(Dev)}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/film`}
          component={WithTracker(Film)}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/board`}
          component={WithTracker(Board)}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/chatbot`}
          component={WithTracker(Chatbot)}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/hours`}
          component={WithTracker(Hours)}
        />
        <Route component={WithTracker(FourOhFour)} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
