import React, { Component } from "react";
import { Link } from "react-router-dom";

export class ChatbotHero extends Component {
  render() {
    let learnmore;
    const bgImage = {
      backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(./img/mob-banner.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "top",
    }
    const cbbutton = {
      //backgroundColor: "rgb(0, 0, 0, 0)",
      //borderWidth: "1px"
    };
    if (this.props.learnmore === "true") {
      learnmore = (
        <Link className="button is-outlined is-inverted is-black" to="/hours" style={cbbutton}>
          <span>Learn More</span>
          <span className="icon">
            <i className="mdi mdi-chevron-right" />
          </span>
        </Link>
      );
    }
    return (
      <div
        className="hero is-medium"
        style={ bgImage }
      >
        <div className="hero-body" style={{ paddingLeft: "0px" }}>
          <div className="columns is-vcentered">
            <div className="column is-narrow is-offset-2">
              {/*<figure className="image is-128x128">
                <img
                  className="is-rounded shadowimg"
                  src="./img/chatbot-logo.png"
                  alt="Chatbot logo"
                />
              </figure>*/}
            </div>

            <div className="column is-6">
              <h1 className="title is-2">Hours</h1>
              <h2 className="subtitle is-4 has-text-white">
                Official Music of Bakersfield Volunteer Tracking System
              </h2>
            </div>
            <div className="is-narrow">
              <figure className="image is-128x128 logospacer"></figure>
            </div>
            <div className="column is-narrow">{learnmore}</div>
            <div className="column is-2"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatbotHero;
