import React, { Component } from "react";
import AboutTiles from "../components/AboutTiles";
import Helmet from "../components/Helmet.js";

import ChatbotHero from "../components/ChatbotHero";

export class Homepage extends Component {
  componentDidMount = () => {
    require("../js/particles.min.js");
    require("../js/homepage-header.js");
    window.loadParticles();
  };

  render() {
    return (
      <React.Fragment>
        <Helmet
          title="Home"
          desc="Isaac Kim is an undergraduate at Brown University. He's a violinist, a web developer, a cinematographer, and board member."
        />
        <div className="hero is-fullheight">
          <div
            id="particle-container"
            className="bgStyle hero-body"
            style={{ padding: 0 }}
          >
            <div className="wordmarkwrapper home-anim">
              <img
                className="zoomwordmark invert wordmark"
                src="wordmark.svg"
                alt="site wordmark"
              />
              <p className="subtitle is-4 mainsubtitle">Home</p>
            </div>
            <div id="particles-js" className="fullpagediv"></div>
          </div>
          <div className="dark-star-gradient">
            <a
              id="homedown"
              className="icon is-large button is-primary is-round"
              href="#about"
            >
              <span className="mdi mdi-chevron-down mdi-24px"></span>
            </a>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="columns">
              <div id="about" className="column is-5 is-offset-2">
                <p className="title is-1">I'm <h1 className="title is-1" style={{display: "inline"}}>
                  Isaac Kim
                </h1>,</p>
                <h2 className="subtitle is-2">
                  a student, musician, developer, cinematographer, and board
                  member.
                </h2>
              </div>
              <div className="column is-3">
                <figure className="image is-3-by-2">
                  <img
                    src="./img/isaac-concert.jpg"
                    alt="Isaac playing the violin"
                  />
                </figure>
              </div>
            </div>
            <div className="column is-8 is-offset-2 nopadding">
              <hr style={{ backgroundColor: "#aaaaaa" }} />
            </div>
            <AboutTiles />
          </div>
        </div>
        <ChatbotHero learnmore="true" />
      </React.Fragment>
    );
  }
}

export default Homepage;
