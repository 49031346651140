import React, { Component } from "react";

export class MobTrackingMain extends Component {
  render() {
    return (
      <div className="content">
        <p className="title is-3" id="h-about">About</p>
        <p>
          Hours has been Music of Bakersfield's official volunteer tracking system since mid-2018, replacing time-consuming manual input methods with a faster, more efficient, and more secure method.
        </p>
        <p>
          Hours is a custom-built solution. If your organization could benefit from a program similar to this, do not hesitate to reach out.
        </p>
        <hr />
        <p className="title is-3 " id="h-features">Features</p>
        <ul>
          <li>
            Modern user interface with easy-to-use input system
          </li>
          <li>
            PDF report generator
          </li>
          <li>
            Volunteer log-in and access
          </li>
          <li>
            Google Sheets database with comprehensive logging information for administrator accountability
          </li>
          <li>
            Separate site for administrator reports and statistic tracking
          </li>
        </ul>
        <hr />
        <p className="title is-3 " id="h-volunteers">Volunteers</p>
        <p>Log in at <a href="https://hours.musicofbakersfield.org">hours.musicofbakersfield.org</a> with your username and password to view your information. High security users can log in at <a href="https://hours.musicofbakersfield.org/highsecurity">hours.musicofbakersfield.org/highsecurity</a>.</p>
        <hr style={{marginBottom: "6px"}} />
        <p className="subtitle is-7">Copyright © 2018-2020 Isaac Kim · Last updated on 1/17/2020 by an administrator.</p>
      </div>
    );
  }
}

export default MobTrackingMain;
