import React, { Component } from 'react'

export class PageHero extends Component {
  render() {
    const bgimage = this.props.bgurl !== undefined ? { backgroundImage: "url("+this.props.bgurl+")" } : null
    const particlesjs = this.props.particlesjs === true ? "particles-js" : null
    const arrowhref = this.props.arrowhref !== undefined ? this.props.arrowhref : null
    const subtitle = this.props.subtitle !== undefined ? this.props.subtitle : null
    return (
      <React.Fragment>
      <div className="hero is-fullheight mainhero">
          <div className="bgStyle hero-body" style={{ padding: 0 }}>
            <div className="wordmarkwrapper wwanim">
              <img
                className="invert wordmark"
                src="wordmark.svg"
                alt="site wordmark"
              />
              <p className="subtitle is-4 mainsubtitleother">{ subtitle }</p>
            </div>

            <div
              className="fullpagediv"
              style={ bgimage }
              id={ particlesjs }
            ></div>
          </div>
          <div className="down-button-container">
            <a
              className="icon is-large button is-primary is-round down-arrow"
              href={ arrowhref }
            >
              <span className="mdi mdi-chevron-down mdi-24px"></span>
            </a>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PageHero
