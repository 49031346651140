import React, { Component } from "react";

export class ChatbotLevel extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <nav className="level" style={{ paddingTop: "24px" }}>
              <div className="level-item has-text-centered">
                <div>
                  <p className="heading">Users</p>
                  <p className="title">320,000</p>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <p className="heading">Discord Members</p>
                  <p className="title">4300</p>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <p className="heading">Messages Sent</p>
                  <p className="title">450,000+</p>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <p className="heading">Uptime</p>
                  <p className="title">2 years</p>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChatbotLevel;
