import React, { Component } from "react";
import { Link } from "react-router-dom";

export class AboutTiles extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="notification is-primary mobile-only">
              Tap on each tile to learn more.
            </div>

            <div className="tile is-ancestor">
              <div className="tile">
                <div className="tile is-parent is-vertical is-6">
                  <article
                    id="student-tile"
                    className="tile is-child notification tile-transparent overlay-parent"
                  >
                    <div className="columns is-mobile overlay-column">
                      <div className="column">
                        <p className="title">Undergraduate</p>
                        <p className="subtitle">@ Brown University</p>
                        <p>Class of 2023</p>
                        <p>Cognitive Science, Pre-Med</p>
                      </div>
                      <div className="column is-narrow iconwrapper">
                        <figure className="image">
                          <img
                            id="brownlogo"
                            src="/img/brown-logo.png"
                            alt="Brown University Logo"
                          />
                        </figure>
                      </div>
                    </div>
                    <a
                      className="tile-overlay"
                      href="https://directory.brown.edu/?search_string=Isaac%20Y%20Kim"
                    >
                      <div className="tile-hover-content">
                        <div className="button is-primary">Verify Status</div>
                      </div>
                    </a>
                  </article>

                  <article className="tile is-child notification tile-transparent">
                    <div className="columns is-mobile overlay-column">
                      <div className="column">
                        <p className="title">Musician</p>
                        <p className="subtitle">Violinist since '05</p>
                      </div>
                      <div className="column is-narrow iconwrapper">
                        <span className="mdi mdi-music-clef-treble icon is-large mdi-48px"></span>
                      </div>
                    </div>
                    <Link
                      className="tile-overlay"
                      to={`${process.env.PUBLIC_URL}/music`}
                    >
                      <div className="tile-hover-content">
                        <div className="button is-primary">
                          More Information
                        </div>
                      </div>
                    </Link>
                  </article>

                  <article className="tile is-child notification tile-transparent">
                    <div className="columns is-mobile overlay-column">
                      <div className="column">
                        <p className="title">Developer</p>
                        <p className="subtitle">Web Technologies</p>
                      </div>
                      <div className="column is-narrow iconwrapper">
                        <span className="mdi mdi-xml icon is-large mdi-48px"></span>
                      </div>
                    </div>
                    <Link
                      className="tile-overlay"
                      to={`${process.env.PUBLIC_URL}/dev`}
                    >
                      <div className="tile-hover-content">
                        <div className="button is-primary">
                          More Information
                        </div>
                      </div>
                    </Link>
                  </article>
                </div>
                <div className="tile is-parent is-vertical is-6">
                  <article className="tile is-child notification tile-transparent">
                    <div className="columns is-mobile overlay-column">
                      <div className="column">
                        <p className="title">Filmmaker</p>
                        <p className="subtitle">
                          Cinematography, Editing, Coloring
                        </p>
                        <p>
                          Accepting weekend-only contracts in Providence, RI
                          starting mid-March 2020
                        </p>
                      </div>
                      <div className="column is-narrow iconwrapper">
                        <span className="mdi mdi-video-vintage icon is-large mdi-48px"></span>
                      </div>
                    </div>
                    <Link
                      className="tile-overlay"
                      to={`${process.env.PUBLIC_URL}/film`}
                    >
                      <div className="tile-hover-content">
                        <div className="button is-primary">
                          More Information
                        </div>
                      </div>
                    </Link>
                  </article>
                  <article className="tile is-child notification tile-transparent">
                    <div className="columns is-mobile overlay-column">
                      <div className="column">
                        <p className="title">Board Member</p>
                        <p className="subtitle">
                          Nonprofit and Organizational Experience
                        </p>
                      </div>
                      <div className="column is-narrow iconwrapper">
                        <span className="mdi mdi-account-group icon is-large mdi-48px"></span>
                      </div>
                    </div>
                    <Link
                      className="tile-overlay"
                      to={`${process.env.PUBLIC_URL}/board`}
                    >
                      <div className="tile-hover-content">
                        <div className="button is-primary">
                          More Information
                        </div>
                      </div>
                    </Link>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AboutTiles;
