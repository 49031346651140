import React, { Component } from "react";
import { Link } from "react-router-dom";

export class ChatbotHero extends Component {
  render() {
    let learnmore;
    const cbbutton = {
      backgroundColor: "rgb(18, 189, 170)",
      borderWidth: "0px",
      
    };
    if (this.props.learnmore === "true") {
      learnmore = (
        <Link className="button" to="/chatbot" style={cbbutton}>
          <span>Learn More</span>
          <span className="icon">
            <i className="mdi mdi-chevron-right"></i>
          </span>
        </Link>
      );
    }
    return (
      <div
        className="hero is-medium"
        style={{
          backgroundImage: "url(./chatbot-bg.svg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top"
        }}
      >
        <div className="hero-body" style={{ paddingLeft: "0px" }}>
          <div className="columns is-vcentered">
            <div className="column is-narrow is-offset-2">
              <figure className="image is-128x128">
                <img
                  className="is-rounded shadowimg"
                  src="./img/chatbot-logo.png"
                  alt="Chatbot logo"
                />
              </figure>
            </div>

            <div className="column is-6">
              <h1 className="title is-2">CH4TB0T</h1>
              <h2 className="subtitle is-4 has-text-white">
                Official EarnCoins.gg Discord Bot
              </h2>
            </div>
            <div className="column is-narrow">{learnmore}</div>
            <div className="column is-2"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatbotHero;
