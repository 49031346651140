/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

export class ChatbotMenu extends Component {
  render() {
    return (
      <React.Fragment>
        <aside className="menu">
          <p className="menu-label">General</p>
          <hr style={{ marginTop: "0px", marginBottom: "12px" }} />
          <ul className="menu-list">
            <li>
              <a href="#cb-about">About</a>
            </li>
            <li>
              <a href="#cb-disclaimer">Disclaimer</a>
            </li>
          </ul>
          <p className="menu-label has-text-underlined">Documentation</p>
          <hr
            className="is-marginless"
            style={{ marginTop: "0px", marginBottom: "12px" }}
          />
          <p className="menu-label">Member+</p>
          <ul className="menu-list">
            <li>
              <a href="#cb-ping">Ping the bot</a>
            </li>
            <li>
              <a href="#cb-livestream">Toggle livestream channel visibility</a>
            </li>
            <li>
              <a href="#cb-roulette">Play roulette</a>
            </li>
            <li>
              <a href="#cb-music">Play music</a>
            </li>
            <li>
              <a href="#cb-chat">Chat with the chatbot</a>
            </li>
          </ul>
          <p className="menu-label">Moderator+</p>
          <ul className="menu-list">
            <li>
              <a href="#cb-warn">Warn a user</a>
            </li>
            <li>
              <a href="#cb-broadcast">Broadcast a message</a>
            </li>
            <li>
              <a href="#cb-staffhelp">Staff Help Program</a>
            </li>
            <li>
              <a href="#cb-pin">Manage pinned messages</a>
            </li>
            <li>
              <a href="#cb-fun">Fun bonuses</a>
            </li>
          </ul>
          <p className="menu-label">Admin+</p>
          <ul className="menu-list">
            <li>
              <a href="#cb-purge">Purge chat messages</a>
            </li>
            <li>
              <a href="#cb-reboot">Reboot the bot</a>
            </li>
            <li>
              <a href="#cb-rainbowfrog">React with RainbowFrog</a>
            </li>
          </ul>
          <p className="menu-label">Owner+</p>
          <ul className="menu-list">
            <li>
              <a href="#cb-changelog">Create changelog</a>
            </li>
          </ul>
        </aside>
      </React.Fragment>
    );
  }
}

export default ChatbotMenu;
