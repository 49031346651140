import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export class HelmetClass extends Component {
  render() {
    return (
      <Helmet>
          <title>Isaac Kim - {this.props.title}</title>
          <meta name="description" content={this.props.desc} />
        </Helmet>
    )
  }
}

export default HelmetClass
