/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

export class ChatbotMenu extends Component {
  render() {
    return (
      <React.Fragment>
        <aside className="menu" style={{minWidth: "200px"}}>
          <p className="menu-label">General</p>
          <hr style={{ marginTop: "0px", marginBottom: "12px" }} />
          <ul className="menu-list">
            <li>
              <a href="#h-about">About</a>
            </li>
            <li>
              <a href="#h-features">Features</a>
            </li>
            <li>
              <a href="#h-volunteers">Volunteers</a>
            </li>
          </ul>
        </aside>
      </React.Fragment>
    );
  }
}

export default ChatbotMenu;
