import React, { Component } from "react";
import MobTrackingHero from "../components/MobTrackingHero";
import MobTrackingMenu from "../components/MobTrackingMenu";
import MobTrackingLevel from "../components/MobTrackingLevel";
import MobTrackingMain from "../components/MobTrackingMain";
import Helmet from "../components/Helmet"

export class Hours extends Component {
  render() {
    return (
      //26a89b
      <React.Fragment>
        <Helmet
          title="Hour Tracking Software"
          desc="Hours has been Music of Bakersfield's official volunteer tracking system since mid-2018, replacing time-consuming manual input methods with a faster, more efficient, and more secure method."
        />
        <MobTrackingHero />
        <MobTrackingLevel />
        <div className="columns">
          <div className="column is-offset-2">
            <MobTrackingMain />
          </div>
          <div className="column is-narrow">
            <MobTrackingMenu />
          </div>
          <div className="column is-2"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default Hours;
