import React, { Component } from "react";

export class ChatbotDocs extends Component {
  render() {
    return (
      <div className="content">
        <p className="title is-3" id="cb-about">About</p>
        <p>
          CH4TB0T has been serving the EarnsCoins community since early 2018,
          serving as the primary link between the main website and the Discord
          server by transferring messages from the website chat to the Discord
          server. This functionality allows moderators to monitor the site for
          issues from their phones and allows users to interact with website
          members without logging in.
        </p>
        <p>
          On top of this functionality, the bot features a basic warning system
          for staff and a number of server management commands.
        </p>
        <hr />
        <p className="title is-3 " id="cb-disclaimer">Disclaimer</p>
        <p>
          Not all functionality and commands are listed here for security
          purposes. If you are a staff member and would like to request a
          complete list, send an email to admin@hyperninjas.com.
        </p>
        <hr />
        <p className="title is-3 ">Commands</p>
        <p className="title is-5 ">Member+</p>
        <ul>
          <li id="cb-ping">
            <strong>-ping</strong>&nbsp;&nbsp;Tests the bot’s latency and
            connection.
          </li>
          <li id="cb-livestream">
            <strong>-ls [on/off]</strong>&nbsp;&nbsp;Toggles the visibility of
            the Livestream channel. "off" hides the channel, "on" shows it. Only
            works for non-administrators.
          </li>
          <li id="cb-roulette">
            <strong>-roulette</strong>&nbsp;&nbsp;Play a game of roulette! 1/6
            chance of death.
          </li>
          <li id="cb-music">
            <strong>-join</strong>&nbsp;&nbsp;The bot will join a voice channel
            and play On and On by Cartoon.
          </li>
          <li>
            <strong>-leave</strong>&nbsp;&nbsp;The bot will leave the voice
            channel.
          </li>
          <li id="cb-chat">
            <strong>- [message]</strong>&nbsp;&nbsp;Talk with CH4TB0T directly!{" "}
            <strong>Only works in #chatbot</strong>.
          </li>
        </ul>
        <p className="title is-5">Moderator+</p>
        <ul>
          <li>
            <strong id="cb-warn">-warn [begging/adv/spam/harass/english] [@username/userID]</strong>&nbsp;&nbsp;Warns the user. You will need to manually delete offending messages. To check for prior infractions, search for their userID.
          </li>
          <li>
            <strong id="cb-broadcast">-bc [message]</strong>&nbsp;&nbsp;Broadcasts a message. Does not ping users.
          </li>
          <li id="cb-staffhelp">
            <strong>-optin</strong>&nbsp;&nbsp;Opts staff into the @staff-help role. Server members can ping this role anytime they need assistance. If you do not wish to be pinged, see the command below.
          </li>
          <li>
            <strong>-optout</strong>&nbsp;&nbsp;Opts staff out of the @staff-help role.
          </li>
          <li id="cb-pin">
            <strong>-pin [messageID]</strong>&nbsp;&nbsp;Pins a message.
          </li>
          <li>
            <strong>-unpin [messageID]</strong>&nbsp;&nbsp;Unpins a message.
          </li>
          <li id="cb-fun">
            <strong>-party</strong>&nbsp;&nbsp;Sends a party in an embed.
          </li>
        </ul>
        <p className="title is-5">Admin+</p>
        <ul>
          <li id="cb-purge">
            <strong>-purge [#]</strong>&nbsp;&nbsp;Deletes the previous # messages from the channel. Only works with recent messages due to Discord limitations.
          </li>
          <li id="cb-reboot">
            <strong>-reboot</strong>&nbsp;&nbsp;Reboots CH4TB0T. See #hyper-log for progress. <strong>Do not spam this command</strong>.
          </li>
          <li id="cb-rainbowfrog">
            <strong>-rf [messageID]</strong>&nbsp;&nbsp;Adds the RainbowFrog reaction to any message.
          </li>
        </ul>
        <p className="title is-5">Owner+</p>
        <ul>
          <li id="cb-changelog">
            <strong>-changelog [parameters]</strong>&nbsp;&nbsp;Documentation present in official handbook.
          </li>
        </ul>
        <hr style={{marginBottom: "6px"}} />
        <p className="subtitle is-7">Copyright © 2018-2020 Isaac Kim · Last updated on 1/17/2020 by an administrator.</p>
      </div>
    );
  }
}

export default ChatbotDocs;
