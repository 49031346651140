import React, { Component } from 'react'
import Helmet from "../components/Helmet"

export class FourOhFour extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet
            title="404"
            desc="Error 404"
          />
        <div className="hero is-danger">
            <div className="hero-body">
              <div className="container">
                <div className="columns">
                  <div className="column is-8 is-offset-2">
                    <h1 className="title">404</h1>
                    <h2 className="subtitle">Page Not Found</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    )
  }
}

export default FourOhFour
