import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="content has-text-centered">
            <p>
              <strong>Portfolio</strong> by{" "}
              <a href="https://isaackim.me">Isaac Kim</a>.
            </p>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
