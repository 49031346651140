import React, { Component } from "react";
import FilmTilesTop from "../components/FilmTilesTop";
import PageHero from "../components/PageHero"
import Helmet from "../components/Helmet"

export class Film extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet
          title="Film and Cinematography"
          desc="Actively working on narrative projects, Isaac Kim has been creating videos since 2018."
        />
        <PageHero subtitle="Visual Arts Portfolio" bgurl="./img/filmbg2.jpg" arrowhref="#" />
        <FilmTilesTop />
      </React.Fragment>
    );
  }
}

export default Film;
