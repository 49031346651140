import React, { Component } from "react";

export class OldBoardPositions extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="columns"
          style={{ marginTop: "12px", marginRight: "0px" }}
        >
          <div className="column is-2" style={{ paddingLeft: "24px" }}>
            <img
              src="/img/mob.jpg"
              alt="Music of Bakersfield logo"
              style={{ maxHeight: "100px", float: "right" }}
            ></img>
          </div>
          <div className="column is-8">
            <p className="title">Music of Bakersfield</p>
            <p className="subtitle" style={{ marginBottom: "6px" }}>
              Founder and President
            </p>
            <p className="subtitle is-6">Q3 2017 - Q2 2019 (2 years)</p>
            <p className="subtitle" style={{ marginBottom: "6px" }}>
              Founder
            </p>
            <p className="subtitle is-6">Q3 2015 - Q2 2017 (2 years)</p>
            <p>Nonprofit Organization</p>
          </div>
        </div>
        <div
          className="columns"
          style={{ marginTop: "12px", marginRight: "0px" }}
        >
          <div className="column is-2" style={{ paddingLeft: "24px" }}>
            <img
              src="/img/hosa-sths.jpg"
              alt="STHS HOSA logo"
              style={{ maxHeight: "100px", float: "right" }}
            />
          </div>
          <div className="column is-8">
            <div>
              <p className="title">STHS HOSA</p>
              <p className="subtitle" style={{ marginBottom: "6px" }}>
                President, Board Member
              </p>
              <p className="subtitle is-6">Q3 2018 - Q2 2019 (1 year)</p>
              <p className="subtitle" style={{ marginBottom: "6px" }}>
                Representative, Board Member
              </p>
              <p className="subtitle is-6">Q3 2015 - Q2 2016 (1 year)</p>
              <p>Student Group</p>
            </div>
          </div>
        </div>
        <div
          className="columns"
          style={{ marginTop: "12px", marginRight: "0px" }}
        >
          <div className="column is-2" style={{ paddingLeft: "24px" }}>
            <img
              src="/img/sths-coding-club.jpg"
              alt="STHS Coding Club logo"
              style={{ maxHeight: "100px", float: "right" }}
            ></img>
          </div>
          <div className="column is-8">
            <p className="title">STHS Coding Club</p>
            <p className="subtitle" style={{ marginBottom: "6px" }}>
              President, Board Member
            </p>
            <p className="subtitle is-6">Q3 2017 - Q2 2019 (2 years)</p>
            <p className="subtitle" style={{ marginBottom: "6px" }}>
              Founding Officer, Secretary, Board Member
            </p>
            <p className="subtitle is-6">Q3 2016 - Q2 2017 (1 year)</p>
            <p>Student Group</p>
          </div>
        </div>
        <div
          className="columns"
          style={{ marginTop: "12px", marginRight: "0px" }}
        >
        <div className="column is-2" style={{ paddingLeft: "24px" }}>
            <img
              src="/img/sthsorchestra.jpg"
              alt="STHS Orchestra logo"
              style={{ maxHeight: "100px", float: "right" }}
            ></img>
          </div>
          <div className="column is-8">
            <p className="title">STHS Orchestra</p>
            <p className="subtitle" style={{ marginBottom: "6px" }}>
              President, Executive Board Member
            </p>
            <p className="subtitle is-6">Q3 2017 - Q2 2019 (2 years)</p>
            <p>Departamental Group</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OldBoardPositions;
