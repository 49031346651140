import React, { Component } from "react";
import ChatbotHero from "../components/ChatbotHero";
import MobTrackingHero from "../components/MobTrackingHero";
import PageHero from "../components/PageHero"
import Helmet from "../components/Helmet"

export class Dev extends Component {
  componentDidMount = () => {
    require("../js/particles.min.js");
    require("../js/dev-header.js");
    window.loadParticlesDev();
  };
  render() {
    return (
      <React.Fragment>
        <Helmet
          title="Developer"
          desc="Isaac Kim develops web applications in his free time for both individuals and corporations."
        />
        <PageHero subtitle="Developer" arrowhref="#" particlesjs={true} />
        <ChatbotHero learnmore="true" />
        <MobTrackingHero learnmore="true" />
      </React.Fragment>
    );
  }
}

export default Dev;
