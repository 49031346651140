import React, { Component } from "react";
import ChatbotHero from "../components/ChatbotHero";
import ChatbotMenu from "../components/ChatbotMenu";
import ChatbotLevel from "../components/ChatbotLevel";
import ChatbotDocs from "../components/ChatbotDocs";
import Helmet from "../components/Helmet";

export class Chatbot extends Component {
  render() {
    return (
      //26a89b
      <React.Fragment>
        <Helmet
          title="EarnCoins.gg Chatbot"
          desc="CH4TB0T has been serving the EarnsCoins community since early 2018, serving as the primary link between the main website and the Discord server by transferring messages from the website chat to the Discord server."
        />
        <ChatbotHero />
        <ChatbotLevel />
        <div className="columns">
          <div className="column is-offset-2">
            <ChatbotDocs />
          </div>
          <div className="column is-narrow">
            <ChatbotMenu />
          </div>
          <div className="column is-2"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default Chatbot;
