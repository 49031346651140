/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Navbar extends Component {
  componentDidMount = () => {
    require('../js/navbarjs.js')
  }
  render() {
    return (
      <React.Fragment>
        <nav className="navbar nav-is-clear is-transparent container" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item" to={`${process.env.PUBLIC_URL}/`}>
              <img
                src="./wordmark-logo.svg"
                width={158}
                height={32}
                alt="Site logo"
                id="navlogo"
                className="invert"
              />
            </Link>
            <a
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start">
              <Link className="navbar-item" to={`${process.env.PUBLIC_URL}/music`}>Music</Link>
              <Link className="navbar-item" to={`${process.env.PUBLIC_URL}/dev`}>Code</Link>
              <Link className="navbar-item" to={`${process.env.PUBLIC_URL}/film`}>Cinematography</Link>
              <Link className="navbar-item" to={`${process.env.PUBLIC_URL}/board`}>Board</Link>
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">More</a>
                <div className="navbar-dropdown is-boxed">
                  <a className="navbar-item" href="https://musicofbakersfield.org" target="_blank" rel="noopener noreferrer"><span>Music of Bakersfield</span><figure className="icon"><i className="mdi mdi-open-in-new"></i></figure></a>
                  <Link className="navbar-item" to={`${process.env.PUBLIC_URL}/chatbot`}>CH4TB0T</Link>
                  <Link className="navbar-item" to={`${process.env.PUBLIC_URL}/hours`}>Hours</Link>
                  <a className="navbar-item">Design (Coming Soon)</a>
                  <hr className="navbar-divider" />
                  <a className="navbar-item">Legal (Coming Soon)</a>
                  <a className="navbar-item">Press Kit (Coming Soon)</a>
                </div>
              </div>
            </div>
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <a className="button is-primary is-outlined is-inverted">
                    <strong>Contact</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default Navbar;